import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Pusher from 'pusher-js'

import axios from 'axios'

async function bootstrap(){
  Vue.prototype.$axios = axios
  Vue.prototype.$pusher = new Pusher('232b1ae173ecf3ff9294', {
    cluster: 'eu'
  });

  const appName = 'charlie'

  Vue.prototype.$appName = appName

  //const token = localStorage.getItem('token')

  Vue.prototype.$axios.defaults.baseURL = 'https://api-novartis-'+ appName +'.blackmoonlab.fr'
  //Vue.prototype.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  Vue.prototype.$axios.interceptors.response.use(
      request => {
        return request
      },
      error => {
        if(error.response.status == 401 || error.response.status == 403){
          localStorage.removeItem('token')
          store.dispatch('setUser', null)
          router.push('/login')
        }
        return Promise.reject(error)
      }
  )

  Vue.config.productionTip = false

  router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = store.getters.user != null;

    if (authRequired && !loggedIn) {
      return next('/login');
    }

    next();
  })

/*  if(token != null){
    try {
      const { data } = await Vue.prototype.$axios.get('/index.php')
      store.dispatch('setUser', data)
    }
    catch(e){
      console.log(e)
    }
  }*/

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')

}

bootstrap()