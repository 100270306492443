<template>
  <div id="app">
    <img src="/column.png" alt="">
    <div class="container">
      <router-view/>
    </div>

    <div class="legals">
      <a href="https://medias-storage.blackmoonlab.fr/novartis/cgu.pdf" target="_blank">CGU</a>
      <br>
      <a href="https://medias-storage.blackmoonlab.fr/novartis/noticeWeb.pdf" target="_blank">Notice Web</a>
    </div>
  </div>
</template>

<script>
import HeadBar from "./components/HeadBar";
export default {
  components: {HeadBar},
  async mounted(){
    this.gggg()
    const channelConfig = this.$pusher.subscribe(this.$appName+'-config')
    channelConfig.bind('change', (data) => {
      this.$store.dispatch('setConfig', data)
    })
    const channelState = this.$pusher.subscribe(this.$appName+'-state')
    channelState.bind('change', (data) => {
      this.$store.dispatch('setState', data)
      if(data == 'postshow'){
        this.$router.push('/login')
      }
    })
    setTimeout(async () => {
      if(this.$store.getters.user != null){
        await this.$axios.get('/ping.php')
      }
    }, 4000)
    setInterval(async () => {
      if(this.$store.getters.state != 'postshow'){
        if(this.$store.getters.user != null){
          await this.$axios.get('/ping.php')
        }
      }
    }, 1*30*1000)
  },
  methods: {
    async gggg(){
      let { data } = await this.$axios.get('/config/getConfig.php')
      this.$store.dispatch('setConfig', data)
      let response = await this.$axios.get('/state/getState.php')
      this.$store.dispatch('setState', response.data.stateValue)
      if(response.data.stateValue == 'postshow'){
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style lang="scss">
  * {
    box-sizing: border-box;
    outline: none;
    margin: 0;
    font-family: sans-serif;
  }

  #app {
    display: flex;
    width: 100vw;
    height: 100vh;

    >img {
      height: 100%;
      object-fit: contain;
    }

    .container {
      height: 100vh;
      flex-grow: 1;
      overflow-y: scroll;
    }
  }

  .container {
    width: 80vw;
    min-height: 88vh;
    margin: 0 auto;
  }

  $blue: #1A2A6C;
  $red: #B21F1F;
  $orange: #D15F25;
  $yellow: #FDBB2D;

  .button {
    padding: 5px 10px;
    border: solid 2px $blue;
    text-align: center;
    font-weight: 700;
    color: $blue;
    border-radius: 100px;
    cursor: pointer;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .button:hover {
    color: white;
    background-color: $blue;
  }

  .legals {
    position: fixed;
    bottom: 15px;
    left: 15px;
    a {
      color: white;
      text-decoration: none;
      font-size: 12px;

      &:first-child {
        margin-bottom: 10px;
      }
    }
  }

</style>
